import React from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
export default [
  {
    title: <FormattedMessage id="servicioDropdown1" />,
    link: "/tenido",
  },
  {
    title: <FormattedMessage id="servicioDropdown2" />,
    link: "/calidades",
  },
  {
    title: <FormattedMessage id="servicioDropdown3" />,
    link: "/tallas",
  },
]
