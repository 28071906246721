//useState para el toggle
import React, { useState } from "react"
//import { Link } from "gatsby"
//import AniLink from "gatsby-plugin-transition-link/AniLink"
//para el toggle cuando se vuelve responsive
import { FaAlignRight } from "react-icons/fa"
//usamos css modules
import styles from "../css/navbar.module.css"
//importamos los links y social icons creados en la carpeta constants
import links from "../constants/links"
import { Link, FormattedMessage } from "gatsby-plugin-intl"
import socialIcons from "../constants/social-icons"
//para el logo
import logo from "../images/bravoHatsfinalWeb1.svg"
import Language from "./language"
import DropDown from "./dropdown-component"
import Services from "../constants/dropdownServices"

const Navbar = () => {
  const [isOpen, setNav] = useState(false)
  //funcion para cambiar el estado del toggle
  const toggleNav = () => {
    setNav(isOpen => !isOpen)
  }
  return (
    <nav className={styles.navbar}>
      <div className={styles.navCenter}>
        <div className={styles.navHeader}>
          <img src={logo} alt="Bravo Hats logo" />
          <button
            type="button"
            className={styles.logoBtn}
            onClick={toggleNav}
            aria-label="Toggle button"
          >
            <FaAlignRight className={styles.logoIcon} />
          </button>
        </div>
        {/* if para el estado para mostrar o no el togle*/}
        <ul
          className={
            isOpen
              ? `${styles.navLinks} ${styles.showNav}`
              : `${styles.navLinks}`
          }
        >
          {links.map((item, index) => {
            return (
              <li key={index}>
                <Link to={item.path}>{item.text}</Link>
              </li>
            )
          })}
        </ul>
        <div className={styles.dropdownWrapper}>
          <DropDown
            title={<FormattedMessage id="serviciosNavBar" />}
            items={Services}
          />
        </div>

        <div className={styles.navSocialLinks}>
          {socialIcons.map((item, index) => {
            return (
              <a
                key={index}
                href={item.url}
                target="_blank"
                rel="noreferrer"
                aria-label="redes sociales"
              >
                {item.icon}
              </a>
            )
          })}
        </div>

        <ul className={styles.languaje}>
          <Language />
        </ul>
      </div>
    </nav>
  )
}

export default Navbar
