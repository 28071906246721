import React from "react"
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-intl"
import "../css/flag-icon.scss"
import "./language-styles.scss"
const languageName = {
  "en-US": "English",
  "es-EC": "Español",
  de: "Deutsch",
}

const Language = () => {
  return (
    <IntlContextConsumer>
      {({ languages, language: currentLocale }) =>
        languages.map(language => (
          <div
            className="listaIdiomas"
            key={language}
            onClick={() => changeLocale(language)}
            style={{
              color: currentLocale === language ? `#f1af59` : `243E54`,
            }}
            onKeyDown={() => changeLocale(language)}
            aria-hidden="true"
          >
            {language === "es-EC" ? (
              <i className="flag-icon flag-icon-es"></i>
            ) : null}
            {language === "en-US" ? (
              <i className="flag-icon flag-icon-us"></i>
            ) : null}
            {language === "de" ? <i class="flag-icon flag-icon-de "></i> : null}
            {languageName[language]}
          </div>
        ))
      }
    </IntlContextConsumer>
  )
}

export default Language
