import React from "react"
import styles from "../css/footer2.module.css"
import logo from "../images/bravoHatsfinalWeb2.svg"
import logoAriel from "../images/logoAriel.svg"
import { FaWhatsapp, FaHome } from "react-icons/fa"
import { MdMailOutline } from "react-icons/md"
import socialIcons from "../constants/social-icons"
import { Link, FormattedMessage } from "gatsby-plugin-intl"
const FooterComponent = () => {
  return (
    <div>
      <footer className={styles.footer}>
        <div className={styles.footer__addr}>
          <h1 className={styles.footer__logo}>
            <img src={logo} alt="Bravo Hats logo" />
          </h1>
        </div>

        <ul className={styles.footer__nav}>
          <li className={styles.nav__item}>
            <h3 className={styles.colum__title}>
              <FormattedMessage id="footerText1" />
            </h3>

            <ul className={styles.nav__ul}>
              <li>
                <p>
                  <Link to="/nuestra-empresa">
                    <FormattedMessage id="footerText2" />
                  </Link>
                </p>
              </li>

              <li>
                <p>
                  <Link to="/historia-panama-hat">
                    <FormattedMessage id="footerText3" />
                  </Link>
                </p>
              </li>

              <li>
                <p>
                  <Link to="/contact">
                    <FormattedMessage id="footerText4" />
                  </Link>
                </p>
              </li>
            </ul>
          </li>

          <li className={`${styles.nav__item} ${styles.nav__item__extra}`}>
            <h3 className={styles.colum__title}>
              <FormattedMessage id="footerText5" />
            </h3>

            <ul className={`${styles.nav__ul} ${styles.nav__ul__extra}`}>
              <li>
                <p>
                  <Link to="/galeria">
                    <FormattedMessage id="footerText6" />
                  </Link>
                </p>
              </li>

              <li>
                <p>
                  <Link to="/tenido">
                    <FormattedMessage id="footerText7" />
                  </Link>
                </p>
              </li>

              <li>
                <p>
                  <Link to="/calidades">
                    <FormattedMessage id="footerText8" />
                  </Link>
                </p>
              </li>

              <li>
                <p>
                  <Link to="/tallas">
                    <FormattedMessage id="footerText9" />
                  </Link>
                </p>
              </li>
            </ul>
          </li>

          <li className={styles.nav__item}>
            <h3 className={styles.colum__title}>
              <FormattedMessage id="footerText10" />
            </h3>

            <ul className={styles.nav__ul}>
              <li>
                <p>
                  <FaHome /> Cuenca, Ecuador
                </p>
              </li>

              <li>
                <p>
                  <MdMailOutline /> info@bravohats.com
                </p>
              </li>

              <li>
                <p>
                  <FaWhatsapp /> + 593 992 566 284
                </p>
              </li>
              <li>
                <p>
                  <FaWhatsapp /> + 593 969 685 586
                </p>
              </li>
            </ul>
          </li>
        </ul>

        <div className={styles.icons}>
          {socialIcons.map((item, index) => {
            return (
              <a key={index} href={item.url} target="_blank" rel="noreferrer">
                {item.icon}
              </a>
            )
          })}
        </div>
        <div className={styles.copyrightWrapper}>
          <div className={styles.copyright}>
            copyright &copy; Bravo Hats {new Date().getFullYear()}{" "}
            <FormattedMessage id="copyright" />
          </div>
        </div>
      </footer>
      <div style={{ background: "#d8d8d8" }}>
        <div className={styles.desarrollador} style={{ textAlign: "center" }}>
          <a
            href="https://arielbravo.com/"
            rel="noreferrer"
            target="_blank"
            aria-label="ariel bravo"
          >
            <h2 style={{ fontSize: "14px" }}>
              <FormattedMessage id="desarrollado" />
            </h2>
          </a>
        </div>
        <div style={{ textAlign: "center", paddingBottom: "30px" }}>
          <a
            href="https://arielbravo.com/"
            rel="noreferrer"
            target="_blank"
            aria-label="ariel bravo"
          >
            <img src={logoAriel} alt="Ariel Bravo" height="50" />
          </a>
        </div>
      </div>
    </div>
  )
}

export default FooterComponent
