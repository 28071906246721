// para en el caso de que cambien los nombre,
//podamos hacerlos aqui, y no cambiar en cada componente(ejemplo header y footer)
//en este caso solo cambiamos aqui y se cambia en los dos
//sirve para cualquier herramienta de desarrollo
import React from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
export default [
  {
    path: "/",
    text: <FormattedMessage id="inicio" />,
  },
  {
    path: "/galeria",
    text: <FormattedMessage id="galeria" />,
  },

  {
    path: "/contact",
    text: <FormattedMessage id="contacto" />,
  },
]
