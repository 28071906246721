import React from "react"
import styles from "../css/dropdown.module.css"
import { FaAngleDown } from "react-icons/fa"
import { Link } from "gatsby-plugin-intl"
const DropDown = ({ title, items }) => {
  return (
    <div className={styles.slNav}>
      <ul>
        <li>
          <div className={styles.title}>
            <b>{title}</b> <FaAngleDown />
          </div>

          <ul>
            {items.map((item, index) => {
              return (
                <li key={index}>
                  <Link to={item.link} className={styles.link}>
                    <span>{item.title}</span>
                  </Link>
                </li>
              )
            })}
          </ul>
        </li>
      </ul>
    </div>
  )
}

export default DropDown
