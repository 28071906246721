//layout servira para colocar el header y el footer en todas las paginas
//sin importarlas en cada una de las mismas

import React from "react"
import Navbar from "./nav-bar-component"
import FooterComponent from "./footer"
import "./layout.css"
//usamos children para poder colocar los componentes en el medio
//del footer y header
const Layout = ({ children }) => {
  return (
    <main>
      <Navbar />
      {children}

      <FooterComponent />
    </main>
  )
}
export default Layout
